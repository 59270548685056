<template>
    <div>
        <v-card v-if="showAlertLocation" class="location-alert" @click="handleLocationCheck">
            <div class="card">
              <div class="card-content">
                Localização não permitida
              </div>
            </div>
        </v-card>

        <HeaderMobile 
            v-if="isMobile()" 
            style="position: fixed; top: 0; z-index: 9; width: 100%;" 
            :inMap="true" 
            :handleSheet="handleSheet"
            :waitTime="dataset.waitTime" 
            :counter="dataset.countTimer" 
        />
        <gmap-map ref="mapRef" style="width: 100%; height: 100%; position: fixed; left:0; top:0; overflow:hidden;" :center="dataset.center"
            :options="dataset.options.mapStyle" :zoom="dataset.zoom">

            <gmap-polyline v-if="dataset.historyViewer" v-bind:path.sync="dataset.historyPath" v-bind:options="{
                strokeColor: '#222D6C', icons: dataset.historySymbols
            }">
            v-bind:icons="[{ icon: { path: dataset.lineSymbol, scale: 2, fillColor: '#22bf15', }, offset: '0', repeat: '100px' }]"
            v-bind:options="{ strokeColor: '#222D6C' }">
            </gmap-polyline>

            <template v-if="!dataset.historyViewer && dataset.trackingType != 2">
                <gmap-cluster :averageCenter="true" :gridSize="60" @click="onClusterClick">
                <gmap-marker 
                    v-for="(vehicle, index) in dataset.vehicles"
                    :key="index"
                    :position="{ lat: vehicle.position.lat, lng: vehicle.position.lng }" 
                    :clickable="true"
                    :ref="vehicle.plate"
                    :icon="getRotatedIcon(vehicle)"
                    @click="openInfoWindow(vehicle)">
                </gmap-marker>
                </gmap-cluster>
            </template>

            <template>
                <gmap-info-window v-if="clusterVehicles.length > 0" 
                  :opened="showVehiclesCluster" 
                  @closeclick="handleCloseCluster" 
                  
                  :position="{ lat: clusterVehicles[0].position.lat, lng: clusterVehicles[0].position.lng }">
                  <div class="cluster-title">
                    <span class="title-label-popup">Veículos</span> 
                    <span class="subtitle-label-popup">Sobrepostos</span> 
                  </div>
                  <div class="cluster-list">
                    <template>
                        <v-row v-for="(vehicle, index) in clusterVehicles" :key="index">
                          <v-col class="custom-col-tracking">
                            <div class="custom-card">
                              <v-card-text>
                                <v-row align="center" sm="12">
                                  <v-col cols="6" sm="2">
                                    <component :class="vehicle.icon == 7 ? 'icon-size-truck' : 'icon-size'" :is="getIconComponent(vehicle)" :color="ColorUtils.getColor(vehicle)"></component>
                                  </v-col>
                                  
                                  <v-col cols="8" sm="5">
                                    <v-row no-gutters>
                                      <v-col>
                                        <div class="vehicle-info" :style="{ color: ColorUtils.setColorText(vehicle.equipment.model.type, vehicle.equipment.status), fontWeight: 700}">
                                         {{ vehicle.plate }}
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                      <v-col>
                                        <div style="font-weight: 500;">
                                          {{ vehicle.client.name }}
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="1" sm="5" class="custom-col-flag">
                                    <div :class="ColorUtils.getTemplateFlag(vehicle.equipment.model.type, vehicle.equipment.status)">
                                        <span>{{ getEquipmentTypeName(vehicle.equipment.model.type) }}</span>
                                    </div>
                                    <div>
                                        <v-btn icon @click="handleShowInfos(vehicle)">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </div>
                                  </v-col>

                                </v-row>
                              </v-card-text>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                  </div>
                </gmap-info-window>
            </template>
            

            <template v-if="isMobile() && dataset.trackingType != 2 &&  !dataset.historyViewer">
                <div id="map_canvas">
                    <gmap-marker
                    :position="userLocation"
                    :icon="setIconUser()"
                  ></gmap-marker>    
                </div>
            </template>


            <template v-if="dataset.historyViewer && dataset.trackingType == 2">
                <gmap-marker :key="index" v-for="(item, index) in dataset.historyPath"
                    :position="{ lat: item.lat, lng: item.lng}"
                    :icon="getIconByPrecisionType(item.accurate)"
                    :clickable="true" @click="openHistoryInfoWindow(item)" />
            </template>


            <gmap-info-window v-if="dataset.selectedVehicle !== null && dataset.infoBoxOpened"
                :position="{ lat: dataset.selectedVehicle.position.lat, lng: dataset.selectedVehicle.position.lng }"
                :opened="dataset.infoBoxOpened" @closeclick="closeInfoWindow()">

                <vehicle-infobox :vehicles="dataset.vehicles" :vehicle="dataset.selectedVehicle" :set-vehicle="setVehicle" :trackingType="dataset.trackingType"></vehicle-infobox>

            </gmap-info-window>

            <!-- Histórico - componente com infos veículo -->
            <gmap-info-window
                v-if="dataset.trackingType == 2 && dataset.selectedHistory && dataset.historyViewer && dataset.historyInfoBoxOpened"
                :position="{ lat: dataset.selectedHistory.lat, lng: dataset.selectedHistory.lng }"
                :opened="dataset.historyInfoBoxOpened" @closeclick="closeHistoryInfoWindow()">

                <vehicle-history-infobox :position="dataset.selectedHistory"></vehicle-history-infobox>

            </gmap-info-window>


            <!-- mock test técnicos -->
            <!-- <gmap-info-window :position="{ lat: -29.996800, lng: -51.202370 }" :opened="dataset.technicianInfoBoxOpened"
                @closeclick="dataset.technicianInfoBoxOpened = false">

                <technician-infobox></technician-infobox>

            </gmap-info-window> -->

            <!-- <gmap-info-window :position="{ lat: -29.996800, lng: -51.202370 }" :opened="dataset.safeAreaInfoBoxOpened"
                @closeclick="dataset.safeAreaInfoBoxOpened = false">

                <safe-area-infobox></safe-area-infobox>

            </gmap-info-window> -->

        </gmap-map>

        <map-control v-if="!isMobile()" :trackingType="dataset.trackingType" @zoomChange="handleZoomChange" :waitTime="dataset.waitTime" :counter="dataset.countTimer" ></map-control>

        <div v-if="isMobile()" class="control-button-mobile" style="width: 100%; position: fixed; bottom: 0; z-index: 1000;">
            <v-bottom-navigation color="primary" height="80">
                <v-btn :class="{ 'selected-btn': selectedButton === 'mapa', 'not-selected': selectedButton !== 'mapa' }" @click="selectedButton = 'mapa'">
                    <span :class="{ 'selected-span': selectedButton === 'mapa', 'not-selected': selectedButton !== 'mapa' }">Mapa</span>
                    <v-icon :class="{ 'selected-icon': selectedButton === 'mapa', 'not-selected': selectedButton !== 'mapa' }">mdi-map-search-outline</v-icon>
                </v-btn>
        
                <v-btn :class="{ 'selected-btn': selectedButton === 'veiculo', 'not-selected': selectedButton !== 'veiculo' }" @click="handleSheet('veiculo')">
                    <span :class="{ 'selected-span': selectedButton === 'veiculo', 'not-selected': selectedButton !== 'veiculo' }">Veículo</span>
                    <v-icon :class="{ 'selected-icon': selectedButton === 'veiculo', 'not-selected': selectedButton !== 'veiculo' }">mdi-car</v-icon>
                </v-btn>
        
                <v-btn :class="{ 'selected-btn': selectedButton === 'aproximacao', 'not-selected': selectedButton !== 'aproximacao' }" @click="handleSheet('aproximacao')">
                    <span :class="{ 'selected-span': selectedButton === 'aproximacao', 'not-selected': selectedButton !== 'aproximacao' }">Aproximação</span>
                    <v-icon :class="{ 'selected-icon': selectedButton === 'aproximacao', 'not-selected': selectedButton !== 'aproximacao' }">mdi-speedometer</v-icon>
                </v-btn>
        
                <v-btn :class="{ 'selected-btn': selectedButton === 'support', 'not-selected': selectedButton !== 'support' }" @click="handleSheet('support')">
                    <span :class="{ 'selected-span': selectedButton === 'support', 'not-selected': selectedButton !== 'support' }">Suporte</span>
                    <v-icon :class="{ 'selected-icon': selectedButton === 'support', 'not-selected': selectedButton !== 'support' }">mdi-comment-processing-outline</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </div>
        
        
        <!-- # Rastreino - Componente para filtrar veículos -->
        <vehicle-filter 
            :vehicles="dataset.vehicles" 
            :open-info-window="openInfoWindowWithZoon"
            @update-vehicles="updateVehicles" 
            @update-filter-info="updateFilterInfo"
            @update-filter-serial="updateFilterSerial" 
        ></vehicle-filter>
        
        <!-- # Antenista - Componente para filtrar veículos -->
        <vehicle-nearby-filter 
            :vehicles="dataset.vehicles" 
            @update-vehicles="updateVehicles" 
            @update-filter-info="updateFilterInfo" 
            :open-info-window="openInfoWindowWithZoon" 
            @update-filter-serial="updateFilterSerial" 
            @update-filter-gateway="updateFilterGateway"
        ></vehicle-nearby-filter>

        <!-- # Histórico - componente para filtrar veículos -->
        <vehicle-history-filter :vehicles="dataset.vehicles" :historyPath.sync="dataset.historyPath" :zoomMap="zoomMap" :clear-infos-history="clearInfosHistory"></vehicle-history-filter>

        <!-- Modal para bloqueio -->
        <modal-block :vehicle="dataset.selectedVehicle"></modal-block>
        <!-- Modal para dormir -->
        <modal-sleep :vehicle="dataset.selectedVehicle"></modal-sleep>
        <!-- Modal para acordar -->
        <modal-wakeup :vehicle="dataset.selectedVehicle"></modal-wakeup>
        <!-- Modal para desbloqueio -->
        <modal-unblock :vehicle="dataset.selectedVehicle" :closeWindow="closeInfoWindow"></modal-unblock>
        
        <!-- # Veículo - Componente com informações do veículo -->
        <v-bottom-sheet v-model="dataset.viewSheetVehicle" hide-overlay>
            <sheet-vehicle 
                :trackingType="dataset.trackingType"
                :vehicle="dataset.selectedVehicle"
                @close="handleCloseSheetVehicle" 
            />
        </v-bottom-sheet>

        <!-- # Aproximação - Componente com informações do veículo -->
        <v-bottom-sheet v-model="dataset.viewSheetApproximation" hide-overlay>
            <sheet-approximation 
                :vehicle="dataset.selectedVehicle" 
                @close="handleCloseSheetApproximation" />
        </v-bottom-sheet>

        <!-- # Settings - Componente de configurações do antenista -->
        <v-bottom-sheet v-model="dataset.viewSheetSettings" hide-overlay>
            <sheet-settings 
                :vehicle="dataset.selectedVehicle"
                @close="handleCloseSheetSettings"
            />
        </v-bottom-sheet>
        
        <!-- # Location - Componente para solicitar a localização -->
        <v-bottom-sheet v-model="dataset.viewSheetLocation" hide-overlay>
            <v-card class="custom-bottom-sheet">
                <v-card-title class="headline d-flex justify-space-between align-center">
                    Localização não <br /> permitida!
                    <v-btn icon @click="closeViewSheetLocation">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    Sua localização não aparece no mapa porque você recusou compartilha-la com a plataforma.
                    <br /> <br/>
                    Para você aparecer, permita a sua localização através do botão abaixo.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="requestLocationPermission" :height="56" width="100%" color="secondary" elevation="0">Permitir Localização</v-btn>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
    </div>
    
</template>

<script>
import Vue, { set } from "vue";
import axios from "axios";
import { eventHub } from '@/main';
import VueLodash from 'vue-lodash';
import object from "@/common/util/object";
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import CarIcon from '@/components/icons/CarIcon2d.vue';
import BusIcon from '@/components/icons/BusIcon.vue';
import VanIcon from '@/components/icons/VanIcon.vue';
import HatchIcon from '@/components/icons/HatchIcon.vue';
import TruckIcon from '@/components/icons/TrcukIcon2d.vue';
import PickupIcon from '@/components/icons/PickupIcon.vue';
import vehicleIcons from "@/common/data/vehicle-icons.data"
import HeaderMobile from "@/layouts/components/HeaderMobile";
import googleMapsStyle from "@/assets/data/google-maps-style.json";
import MotorcycleIcon from '@/components/icons/MotorcycleIcon.vue';
import ModalBlock from "@/views/tracking/components/ModalBlock.vue";
import MapControl from "@/views/tracking/components/MapControl.vue";
import ModalSleep from "@/views/tracking/components/ModalSleep.vue";
import ModalWakeup from "@/views/tracking/components/ModalWakeup.vue";
import ModalUnblock from "@/views/tracking/components/ModalUnblock.vue";
import SheetVehicle from "@/views/tracking/components/SheetVehicle.vue";
import SheetSettings from "@/views/tracking/components/SheetSettings.vue";
import VehicleFilter from "@/views/tracking/components/VehicleFilter.vue";
import VehicleInfoBox from "@/views/tracking/components/VehicleInfoBox.vue";
import SafeAreaInfoBox from "@/views/tracking/components/SafeAreaInfoBox.vue";
import TechnicianInfoBox from "@/views/tracking/components/TechnicianInfoBox.vue";
import SheetApproximation from "@/views/tracking/components/SheetApproximation.vue";
import VehicleNearbyFilter from "@/views/tracking/components/VehicleNearbyFilter.vue";
import VehicleNearbyInfoBox from "@/views/tracking/components/VehicleNearbyInfoBox.vue";
import VehicleHistoryFilter from "@/views/tracking/components/VehicleHistoryFilter.vue";
import VehicleHistoryInfoBox from "@/views/tracking/components/VehicleHistoryInfoBox.vue";
import VehicleTrackerInfoBox from "@/views/tracking/components/VehicleTrackerInfoBox.vue";
import MarkerIcon from "@/components/icons/Marker.vue";

import ColorUtils from "@/common/util/ColorUtils";
import { update } from "lodash";

export default {
    name: "Tracking",

    components: {
        HeaderMobile,
        'gmap-cluster': GoogleMapCluster,
        'modal-block': ModalBlock,
        'modal-sleep': ModalSleep,
        'map-control': MapControl,
        'modal-wakeup': ModalWakeup,
        'sheet-vehicle':SheetVehicle,
        'modal-unblock': ModalUnblock,
        'sheet-settings': SheetSettings,
        'vehicle-filter': VehicleFilter,
        'vehicle-infobox': VehicleInfoBox,
        'sheet-approximation': SheetApproximation,
        // 'safe-area-infobox': SafeAreaInfoBox,
        // 'technician-infobox': TechnicianInfoBox,
        'vehicle-nearby-filter': VehicleNearbyFilter,
        'vehicle-history-filter': VehicleHistoryFilter,
        // 'vehicle-nearby-infobox': VehicleNearbyInfoBox,
        'vehicle-history-infobox': VehicleHistoryInfoBox,
        // 'vehicle-tracker-infobox': VehicleTrackerInfoBox,
    },

    data: () => ({
        ColorUtils,
        selectedButton: 'mapa',
        defaultCenter: { lat: -15.487996302201285, lng: -50.9145378626272 },
        userLocation: null,
        clusterIcons: [],
        clusterVehicles: [],
        showAlertLocation: false,
        showVehiclesCluster: false,
        dataset: {
            viewSheetCluster: false,
            viewSheetVehicle: false,
            viewSheetSettings: false,
            viewSheetLocation: false,
            viewSheetApproximation: false,
            vehicles: [],
            waitTime: 5000,
            countTimer: 0,
            pollingTimeout: null,
            isDestroyed: false,
            historySymbols: [
                {
                    icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 2,
                        strokeColor: '#222D6C',
                        fillColor: '#222D6C',
                        strokeWeight: 4
                    },
                    offset: '0',
                    repeat: '80px'
                },
                {
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 2,
                        strokeColor: '#222D6C',
                        fillColor: '#222D6C',
                        strokeWeight: 4
                    },
                    offset: '100',
                },
            ],
            positions: [],
            position: null,
            historyPath: [],
            vehicleBottomSheet: false,
            vehicleApproximationBottomSheet: false,
            gatewayBottomSheet: false,
            trackingType: 1,
            resolvedVehicles: [],
            vehicleIcons: vehicleIcons,
            status: [{ id: "", name: "Todos" }],
            events: [{ id: "", name: "Selecione um evento" }],
            center: { lat: -15.487996302201285, lng: -50.9145378626272 },
            filter: {
                info: "",
                event: "",
                status: "",
                serial: "",
                gateway: "",
            },
            zoom: null,
            selectedVehicle: null,
            selectedHistory: null,
            gatewaySelected: null,
            trackerInfoBoxOpened: false,
            infoBoxOpened: false,
            technicianInfoBoxOpened: false,
            safeAreaInfoBoxOpened: false,
            nearbyInfoBoxOpened: false,
            historyInfoBoxOpened: false,
            drawer: false,
            historyViewer: false,
            options: {
                mapStyle: {
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    styles: googleMapsStyle,
                    gestureHandling: 'greedy', // auto, cooperative, greedy, none
                }
            }
        },
    }),
    mounted() {
        this.dataset.zoom = 4.8;
        this.dataset.center = { lat: -15.487996302201285, lng: -50.9145378626272 };
        

        if (this.$platform.isMobile()) {
            let elHtml = document.getElementsByTagName('html')[0];
            elHtml.style.overflowY = 'hidden';
            elHtml.style.overflowX = 'hidden';
        }
        
        
        if(!this.isMobile()) {
            localStorage.removeItem('gateway');
            localStorage.removeItem('vehicles');
        }

        this.onHistoryViewer();
        
        this.dataset.trackingType = this.$route.params.type || 1;

        const info = localStorage.getItem('info');
        const serial = localStorage.getItem('serial');
        const gateway = localStorage.getItem('gateway');
        if (info) {
            this.dataset.filter.info = info;
        }
        if (serial) {
            this.dataset.filter.serial = serial;
        }
        if (gateway) {
            this.dataset.filter.gateway = gateway;
        }

       if(this.isMobile()){
        const vehicles = JSON.parse(localStorage.getItem('vehicles'));

        this.dataset.selectedVehicle = vehicles[0];
        this.checkLocationPermission();
        this.handleAlertLocation(); 
       }     
        this.polling();
        this.isDisabled();

    },

    destroyed: function () {
        this.isDestroyed = true;
        
        if (this.$platform.isMobile()) {
            let elHtml = document.getElementsByTagName('html')[0]
            elHtml.style.overflowY = null
            elHtml.style.overflowX = null
        }
    
        this.dataset.vehicles = [];
        this.dataset.historyPath = [];
        this.dataset.vehicleIcons = [];
        this.dataset.selectedVehicle = null;
        this.dataset.selectedHistory = null;
    },

    beforeRouteUpdate(to, from, next) {
        this.dataset.trackingType = to.params.type || 1;  
        const dataUser = JSON.parse(localStorage.getItem("user") || "{}");
        const check = dataUser.permissions.some(permission => permission.path === '/tracking/3');

        if(check && this.dataset.trackingType == 1){
            this.dataset.trackingType = 3;
        }

        if(this.dataset.trackingType == 1) {
            this.isDestroyed = false;
            this.dataset.filter = {
                info: "",
                event: "",
                status: "",
                serial: "",
                gateway: "",
            };
        }

        if(this.dataset.trackingType == 3) {
            this.isDestroyed = false;
        }

        this.dataset.historyPath = [];
        this.dataset.historyViewer = false;
        
        this.dataset.infoBoxOpened = false;
        eventHub.$emit('VEHICLE-LOCK-CHANGE', false)
        eventHub.$emit('VEHICLE-SLEEP-CHANGE', false)
        eventHub.$emit('VEHICLE-WAKEUP-CHANGE', false)
        eventHub.$emit('VEHICLE-FILTER-CHANGE', false)
        eventHub.$emit('VEHICLE-UNBLOCK-CHANGE', false)
        eventHub.$emit('VEHICLE-NEARBY-FILTER-CHANGE', false)
        eventHub.$emit('VEHICLE-HISTORY-FILTER-CHANGE', false)
        next()
    },

    methods: {
        setIconUser() {
            const IconComponent = MarkerIcon;
            const iconInstance = new Vue({
                render: h => h(IconComponent)
            }).$mount();

            const svgString = iconInstance.$el.outerHTML;

           return {
                url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
                anchor: new google.maps.Point(25, 25),
                scaledSize: new google.maps.Size(50, 50),
            };
        },

        async load() {
            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))
            search = !object.isEmpty(search) ? search : null;
            

            if(this.isMobile()){
                const lastCallTime = localStorage.getItem('lastLocationCallTime');
                const now = new Date().getTime();
                if (!lastCallTime || now - lastCallTime > 300000) {
                    try {
                        this.getLocation();
                        localStorage.setItem('lastLocationCallTime', now);

                        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                        await sleep(1000);

                        const location = JSON.parse(localStorage.getItem('location'));
                        this.renderUserMarker(location);
                        this.setIconUser();
                    } catch (locationError) {
                        console.log('Failed to get location: ', locationError);
                    }
                }
            }


            try {
                // console.log('Search in polling: ', search)
                const result = await axios.get('/tracking', { params: { limit: 100000, start: 0, search: search } });

                if(result.data.data.length > this.dataset.vehicles.length ){
                    this.dataset.vehicles = result.data.data;


                    setTimeout(() => {
                        this.fitMapToVehicles();
                    }, 1500);
                    
                }else{
                    this.dataset.vehicles = result.data.data;
                }

                this.resolveVehicles();

                if(this.dataset.selectedVehicle) {
                    const selectedVehicle = this.dataset.vehicles.find(vehicle => vehicle.plate === this.dataset.selectedVehicle.plate);
                    if(selectedVehicle){   
                        this.dataset.selectedVehicle = selectedVehicle;
                    }
                }
                
  
            } catch (error) {
                console.log('error ', error)
                // Adicionar mensage
                this.$dialog.notify.error("Não foi possivel atualizar o mapa", { position: 'top-right', timeout: 5000 });
            }

        },

        async polling() {
            
            clearTimeout(this.dataset.pollingTimeout);

            if (!this.isDestroyed && this.dataset.trackingType != 2) {
                await this.load();
            }
            
            
            this.dataset.countTimer = Math.round(this.dataset.waitTime / 1000);

            const decrementTimer = () => {
                if (this.dataset.countTimer >= 0) {
                    this.dataset.countTimer -= 1;
                    setTimeout(decrementTimer, 1000);
                } else {
                    this.polling();
                }
            };
            
            this.dataset.pollingTimeout = setTimeout(decrementTimer, 1000);
            
        },

        fitMapToVehicles() {
        if (this.dataset.vehicles.length === 0 && !this.userLocation) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        let validPositions = 0;

        this.dataset.vehicles.forEach(vehicle => {
            if (vehicle.position && vehicle.position.lat && vehicle.position.lng) {
            bounds.extend(new google.maps.LatLng(vehicle.position.lat, vehicle.position.lng));
            validPositions++;
            }
        });

        if (this.userLocation) {
            bounds.extend(new google.maps.LatLng(this.userLocation.lat, this.userLocation.lng));
            validPositions++;
        }

        if (validPositions > 0) {
            const map = this.$refs.mapRef.$mapObject;
            map.fitBounds(bounds);
            google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
                const currentZoom = map.getZoom();        
                if (this.isMobile()) {
                    if(validPositions < 2){
                        map.setZoom(currentZoom - 4);
                    }
                    map.setCenter(currentZoom);
                } else {
                    map.setZoom(currentZoom);
                }
            });
        }
        },

        setVehicle(vehicle) {
            this.dataset.selectedVehicle = vehicle;
        },

        onHistoryViewer() {
            eventHub.$on('HISTORY-VIEWER', (value) => {
                this.dataset.historyViewer = value;
            })
        },

        changeVehicleFilter() {
            switch (this.dataset.trackingType) {
                case "1":
                    eventHub.$emit('VEHICLE-FILTER-CHANGE', true)
                    break;
                case "2":
                    eventHub.$emit('VEHICLE-HISTORY-FILTER-CHANGE', true)
                    break;

                default:
                    break;
            }
        },

        getIconComponent(vehicle) {
            const iconComponents = {
                'car.svg': CarIcon,
                'bus.svg': BusIcon,
                'hatch.svg': HatchIcon,
                'motorcycle.svg': MotorcycleIcon,
                'pickup.svg': PickupIcon,
                'truck.svg': TruckIcon,
                'van.svg': VanIcon
            };
        return iconComponents[vehicle.customIcon] || null;
        },

        getIconByPrecisionType(type) {
            let iconUrl = '';

            switch (type) {
                case 1:
                    iconUrl = require('@/assets/images/icons/map-marker-check.svg');
                    break;
                case 0:
                    iconUrl = require('@/assets/images/icons/map-marker-alert-outline.svg');
                    break;
                default:
                    break;
            }

            return {
                url: iconUrl,
                scaledSize: { width: 35, height: 35 },
                size: { width: 35, height: 35 },
                origin: { x: 0, y: 0 },
                anchor: { x: 17.5, y: 35 }
            };
        },

        updateVehicles(newVehicles) {
            this.dataset.vehicles = newVehicles;
        },

        updateFilterGateway(str) {
            this.dataset.filter.gateway = str;
        },

        updateFilterSerial(str) {
            this.dataset.filter.serial = str;
        },

        updateFilterInfo(str) {
            this.dataset.filter.info = str;
        },

        resolveVehicles() {
            for (let item of this.dataset.vehicles) {
                const icon = this.getAssetsByIcon(item.icon);
                // const svg = this.$image.customSvgColor(`!!raw-loader!@/assets/images/vehicle/icons/${icon}`, '[carcolor]', '#FB3A04')

                item.customIcon = icon;

                if(item.battery){
                    item.battery = item.battery + ' mV'
                }else{
                    item.battery = 'Não informada'
                }
            }
        },

        changeVehicleNearbyFilter() {
            eventHub.$emit('VEHICLE-NEARBY-FILTER-CHANGE', true)
        },

        getAssetsByIcon(icon) {
            return this.dataset.vehicleIcons.find((x) => x.id === icon).assets
        },

        getRotatedIcon(vehicle) {
            let rotation = 0;
            if(vehicle.lastPosition ){
                rotation =  this.getAngle([vehicle.lastPosition.lat, vehicle.lastPosition.lng], [vehicle.position.lat, vehicle.position.lng]);
            }

            const iconComponents = {
                'car.svg': CarIcon,
                'bus.svg': BusIcon,
                'hatch.svg': HatchIcon,
                'motorcycle.svg': MotorcycleIcon,
                'pickup.svg': PickupIcon,
                'truck.svg': TruckIcon,
                'van.svg': VanIcon
            };

            const IconComponent = iconComponents[vehicle.customIcon];

            const color = this.ColorUtils.setColorIcon(vehicle.equipment.status, vehicle.equipment.model.type);
            const iconInstance = new Vue({
                render: h => h(IconComponent, { props: { rotation, color } })
            }).$mount();

            const svgString = iconInstance.$el.outerHTML;

            return {
                url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
                anchor: new google.maps.Point(25, 25),
                size: { width: 105, height: 105 },
                scaledSize: new google.maps.Size(50, 50),
                rotation: rotation,
                vehicle: vehicle
            };
        },

        getAngle(from, to) {
            const p1 = { lat: from[0], lng: from[1] };
                const p2 = { lat: to[0], lng: to[1] };

                const angle = google.maps.geometry.spherical.computeHeading(p1, p2);

                return angle;
        },

        openInfoWindow(vehicle) {
            if(this.isMobile()){
                return
            }

            this.closeInfoWindow();
            this.dataset.selectedVehicle = vehicle

            // if (this.dataset.trackingType == 3) {
            //     this.dataset.nearbyInfoBoxOpened = true;
            //     return;
            // }
            
            setTimeout(()=>{
                this.dataset.infoBoxOpened = true;
            }, 150)

        },

        zoomMap(history) {
            if (history.length === 0) {
            return;
            }

            const lastPosition = history[history.length - 1];
            const map = this.$refs.mapRef.$mapObject;

            if (map && lastPosition.lat && lastPosition.lng) {
            const positionLatLng = new google.maps.LatLng(lastPosition.lat, lastPosition.lng);
            map.setCenter(positionLatLng);

            const targetZoom = 15;
            const currentZoom = map.getZoom();

            if (currentZoom <= 10) {
                const zoomStep = (targetZoom - currentZoom) / 10;
                let stepCount = 0;

                const zoomInterval = setInterval(() => {
                if (stepCount < 10) {
                    map.setZoom(currentZoom + zoomStep * stepCount);
                    stepCount++;
                } else {
                    clearInterval(zoomInterval);
                    map.setZoom(targetZoom);
                    setTimeout(() => {
                    this.dataset.infoBoxOpened = true;
                    }, 600);
                }
                }, 50);
            } else {
                setTimeout(() => {
                this.dataset.infoBoxOpened = true;
                }, 150);
            }
            }
        },

        openInfoWindowWithZoon(vehicle) {    
            this.closeInfoWindow();
            if(this.dataset.vehicles.length > 0){
                const vehicleSearch = this.dataset.vehicles.filter(v => v.id == vehicle.id);

                if(!vehicleSearch || vehicleSearch.length == 0){
                    return;
                }

                this.dataset.selectedVehicle = [];
                this.dataset.selectedVehicle = vehicleSearch[0];
                const map = this.$refs.mapRef.$mapObject;
                
                if (map && this.dataset.selectedVehicle.position && this.dataset.selectedVehicle.position.lat && this.dataset.selectedVehicle.position.lng) {
                    const vehicleLatLng = new google.maps.LatLng(this.dataset.selectedVehicle.position.lat, this.dataset.selectedVehicle.position.lng);
                    map.setCenter(vehicleLatLng);

                    const targetZoom = 15;
                    const currentZoom = map.getZoom();

                    if (currentZoom <= 10) {
                        const zoomStep = (targetZoom - currentZoom) / 20;
                        let stepCount = 0;

                        const zoomInterval = setInterval(() => {
                            if (stepCount < 20) {
                                map.setZoom(currentZoom + zoomStep * stepCount);
                                stepCount++;
                            } else {
                                clearInterval(zoomInterval);
                                map.setZoom(targetZoom);
                                setTimeout(() => {
                                    this.dataset.infoBoxOpened = true;
                                }, 600);
                            }
                        }, 50);
                    } else {
                        setTimeout(() => {
                            this.dataset.infoBoxOpened = true;
                        }, 150);
                    }
                }
            }
        },

        clearInfosHistory() {
            this.dataset.historyViewer = false;
            this.dataset.position = null;
            this.dataset.selectedHistory = null;
            this.dataset.historyViewer = false;
            this.dataset.historyInfoBoxOpened = false;
        },

        closeInfoWindow() {
            this.dataset.infoBoxOpened = false;
        },

        closeTrackerInfoWindow() {
            this.dataset.trackerInfoBoxOpened = false;
        },

        closeNearbyInfoWindow() {
            this.dataset.nearbyInfoBoxOpened = false;
        },

        openHistoryInfoWindow(value) {
            this.dataset.selectedHistory = value;
            this.dataset.historyInfoBoxOpened = true;
        },

        closeHistoryInfoWindow() {
            this.dataset.historyInfoBoxOpened = false;
        },

        handleZoomChange(direction) {
            const map = this.$refs.mapRef.$mapObject;
            if (direction === 'in') {
                map.setZoom(map.getZoom() + 1);
            } else if (direction === 'out') {
                map.setZoom(map.getZoom() - 1);
            }
        },

        selectGateway() {
            this.dataset.gatewaySelected = this.dataset.filter.gateway
            this.dataset.filter.gateway = null;
        },

        removeGateway() {
            this.dataset.gatewaySelected = null;
        },

        updateHistoryPath(updatedPath) {
            this.$emit('update:historyPath', updatedPath);
        },

        isMobile() {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },

        isDisabled(){
            const dataUser = JSON.parse(localStorage.getItem("user") || "{}");
            const check = dataUser.permissions.some(permission => permission.path === '/tracking/3');

            if(check && this.dataset.trackingType != 3){
                this.$router.push("/tracking/3");
            }
        },
        
        handleSupport() {
            const gateway = this.dataset.filter.gateway;
            const vehicle = this.dataset.selectedVehicle.plate;
            const phoneNumber = '555140042675';
            const message = `*Olá!*\n\nNecessito de ajuda com o rastreamento do veículo.\n\n*Detalhes do veículo:*\n*Placa:* ${vehicle}\n*Gateway:* ${gateway}\n\nPor favor, entre em contato o mais rápido possível para me ajudar.\n\n*Mensagem enviada via Pilgrim*.`;
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

            const status = window.open(whatsappUrl, '_blank');
            if (!status) {
                this.$dialog.notify.error('Ops! Não é possível enviar mensagem.', { position: 'top-right', timeout: 5000 });
            }
        },

        handleSheet(type){
            if(type === 'veiculo' || type === 'aproximacao'){
                if(this.dataset.vehicles.length < 1){
                this.$dialog.notify.info('Não há veículo no mapa.', { position: 'top-right', timeout: 5000 });
                return;
            }
            }

            this.selectedButton = type;
            this.dataset.selectedVehicle = this.dataset.vehicles[0];
            switch (type) {
                case 'veiculo':
                    this.dataset.viewSheetSettings = false;
                    this.dataset.viewSheetApproximation = false;
                    setTimeout(() => {
                        this.dataset.viewSheetVehicle = true;
                        this.dataset.vehicleBottomSheet = true;
                    }, 500);
                    break;
                case 'aproximacao':
                    this.dataset.viewSheetSettings = false;
                    this.dataset.viewSheetVehicle = false;
                    setTimeout(() => {
                        this.dataset.viewSheetApproximation = true;
                        this.dataset.vehicleApproximationBottomSheet = true;
                    }, 500);
                    break;
                case 'support':
                    this.dataset.viewSheetSettings = false;
                    this.dataset.gatewayBottomSheet = true;
                    this.handleSupport();
                    break;
                case 'settings':
                    this.selectedButton = 'mapa';
                    this.dataset.viewSheetApproximation = false;
                    this.dataset.viewSheetVehicle = false;
                    setTimeout(() => {
                        this.dataset.viewSheetSettings = true;
                    }, 500);
                    break;
                default:
                    break;
            }
        },

        handleCloseSheetVehicle(){
            this.selectedButton = "mapa";
            this.dataset.viewSheetVehicle = false;
            this.dataset.vehicleBottomSheet = false;
        },

        handleCloseSheetApproximation(){
            this.selectedButton = "mapa";
            this.dataset.viewSheetApproximation = false;
            this.dataset.vehicleApproximationBottomSheet = false;
        },

        handleCloseSheetSettings(){
            this.selectedButton = "mapa";
            this.dataset.viewSheetSettings = false;
            this.dataset.gatewayBottomSheet = false;
        },

        checkLocationPermission() {
            if (!navigator.geolocation) {
                this.$dialog.notify.error("Geolocalização não é suportada pelo seu navegador.", { position: 'top-right', timeout: 5000 });
                return;
            }

            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                if (result.state === 'granted' || result.state === 'prompt') {
                    this.getLocation();
                } else {
                    this.dataset.viewSheetLocation = true;
                }
            });
        },

        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.saveLocation(position);
                    },
                    (error) => {
                        this.handleLocationError(error);
                    }
                );
            } else {
                this.$dialog.notify.error(
                    "Geolocalização não é suportada pelo seu navegador.",
                    {
                        timeout: 4000,
                        dismissible: false,
                        position: 'top-right',
                    }
                );
            }
        },

        saveLocation(position) {
            const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };
            this.userLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            const locationStorage = JSON.parse(localStorage.getItem('location'));

            if(!locationStorage){
                localStorage.setItem('location', JSON.stringify(location));

                setTimeout(() => {
                    this.$dialog.notify.info(
                        `Sua localização: ${this.userLocation.lat}, ${this.userLocation.lng}`,
                        {
                            timeout: 6000,
                            dismissible: false,
                            position: 'top-right',
                        }
                    );
                }, 500);
            }


        },

        handleLocation() {
            this.dataset.viewSheetLocation = false;
            this.checkLocationPermission();
        },

        requestLocationPermission() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.saveLocation(position);
                        this.dataset.viewSheetLocation = false;
                    },
                    (error) => {
                        this.handleLocationError(error);
                    }
                );
            } else {
                this.$dialog.notify.error(
                    "Geolocalização não é suportada pelo seu navegador.",
                    {
                        timeout: 4000,
                        dismissible: false,
                        position: 'top-right',
                    }
                );
            }
        },

        handleLocationError(error) {
            this.dataset.viewSheetLocation = true;
            this.showAlertLocation = false;

            let errorMessage = "Não foi possível obter a localização.";
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    errorMessage = "Permissão de localização negada.";
                    break;
                case error.POSITION_UNAVAILABLE:
                    errorMessage = "Informações de localização não estão disponíveis.";
                    break;
                case error.TIMEOUT:
                    errorMessage = "A solicitação para obter a localização expirou.";
                    break;
                case error.UNKNOWN_ERROR:
                    errorMessage = "Ocorreu um erro desconhecido.";
                    break;
            }

            this.$dialog.notify.error(
                errorMessage + " Por favor, permita a localização nas configurações do seu navegador.",
                {
                    timeout: 4000,
                    dismissible: false,
                    position: 'top-right',
                }
            );

            setTimeout(() => {
                this.reopenLocationAlert();
            }, 4200);
        },

        renderUserMarker(position) {
            this.userLocation = {
                lat: position.latitude,
                lng: position.longitude
            };
        },

        handleAlertLocation() {
            if (!navigator.geolocation) {
                if(!this.dataset.viewSheetLocation && !this.userLocation){
                    this.showAlertLocation = true;
                }
            }
        },

        closeViewSheetLocation() {
            this.dataset.viewSheetLocation = false;
            setTimeout(() => {
                this.showAlertLocation = true;
            }, 500);
        },

        handleLocationCheck() {
            this.requestLocationPermission();
        },

        reopenLocationAlert() {
            this.showAlertLocation = true;
        },

        onClusterClick(cluster) {
            const markers = cluster.getMarkers();
            this.clusterVehicles = markers.map(marker => marker.icon.vehicle);
            this.showVehiclesCluster = true;
        },

        handleCloseCluster() {
            this.clusterVehicles = [];
            this.showVehiclesCluster = false;
        },

        handleShowInfos(vehicle) {
            this.handleCloseCluster()
            this.openInfoWindow(vehicle)
        },

        getEquipmentTypeName(type) {
            if(type == 5){
                return 'Mac Lora'
            }

            if(type == 4){
                return 'Mac 4g'
            }
        }
    },
};
</script>

<style lang="scss">

html, body {
    font-family: 'Montserrat', sans-serif !important;
    overflow: hidden;
}

.header-mobile {
    touch-action: none; 
}

.v-btn::before {
    opacity: 0 !important;
}

.custom-bottom-sheet{
    height: 500px !important;
}

.not-selected {
    opacity: 0.6 !important;
    color: #222D6C !important;
}
.selected-icon, .selected-span {
    opacity: 1 !important;
    color: #01bcef !important;
}



.map-filter-card {
    position: absolute;
    bottom: 100px;
    left: 120px;
    right: 120px;

}

.control-button {
    position: absolute;
    bottom: 100px;
    left: 30px;
}

.control-button-mobile {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.custom-control-button {
    height: 45px !important;
}

.filter-card-title {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
}

.list-vehicle-title {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.blocked-vehicle-card {
    .blocked-vehicle-card-title {
        color: var(--v-important-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .blocked-vehicle-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }
}

.position-chip-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.position-info-text {
    color: var(--v-important-base);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.location-alert {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .card {
    background-color: #FF5252;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .card-content {
    text-align: center;
  }

  .cluster-title{
    margin-bottom:20px;
  }

  .gm-style-iw-chr{
    margin-bottom: 40px;
  }

  .gm-style-iw-d{
    max-height: 550px !important;
    margin-top: -15px !important;
  }

  .cluster-list {
    width: 380px;
    height: auto;
    padding: 0px 20px;
  }

  .list-item {
    margin-bottom: 10px;
  }

  .title-label-popup {
    font-family: Montserrat;
    color: var(--v-primary-base);
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    margin-left: 10px;
    position: absolute;
    top:20px;
}

.subtitle-label-popup {
    font-size: 13px;
    font-weight: 500;
    color: var(--v-mediumGray-base);
    margin-left: 10px !important;
    position: absolute !important;
    top: 47px !important;
}

.v-card {
    box-shadow: none !important;
}

.custom-col-tracking{
    padding: 5px 0 !important;
}

.custom-col-flag{
    display: flex;
    justify-content: flex-end !important;
}

.icon-size-truck{
    position: relative;
    left: -5px;
    width: 60px !important;
}

.icon-size{
    position: relative;
    top: 5px;
    left: -3px;
    width: 80px !important;
}

.custom-card {
    height: 100px;
    border: 1px solid #EFEFEF !important;
    // tirar qualquer shadow
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    padding: 0 !important;
}

.vehicle-info{
    width: 100%;
    font-size: 18px;
}

.equipament-blue,
.equipament-red,
.equipament-yellow {
    width: 100% !important;
    padding: 5px;
    border-radius: 5px;
    display: flex;  
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
}

.equipament-blue{
    color: var(--v-primary-base);
    background-color: rgba(34, 45, 108, 0.07);
}

.equipament-red{
    color: var(--v-important-base);
    background-color: rgba(251, 58, 4, 0.07);
}

.equipament-yellow{
    color: var(--v-yellow-base);
    background-color: rgba(255, 183, 3, 0.07);
}
</style>